.chat-box {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  height: 70vh;
}
.chat-controls {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  display: flex;
}
.chat-box span {
  padding: 8px 12px;
  position: relative;
  max-width: 85%;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  word-wrap: break-word;
}
.from-msg,
.to-msg {
  display: flex;
  align-items: flex-start;
}
.from-msg {
  justify-content: flex-end;
  display: flex;
  margin: 5px;
}
.to-msg {
  justify-content: flex-start;
  display: flex;
  margin: 5px;
}
.from-msg span {
  background: #ffd8c0;
  padding: 8px 12px;
  max-width: 85%;
  border-radius: 4px;
  position: relative;
}
.to-msg span {
  background: #d2a0f7;
  padding: 8px 12px;
  max-width: 85%;
  border-radius: 4px;
  position: relative;
}
.title {
  cursor: default;
}

.btn-live-session {
  margin-top: 5px;
  cursor: pointer;
  border: none;
  width: auto;
  height: 34px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.form-control {
  border: none;
  width: 90%;
  outline: none;
}
.btn-primary {
  background-color: #810fd7 !important;
  cursor: pointer;
  color: white;
  font-weight: bold;
  float: right;
  height: 30px;
  border: none;
  border-radius: 5px;
  width: 55px;
  margin-top: auto;
  margin-left: auto;
}
.chat-box span::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #d2a0f7;
  border-left: 10px solid transparent;
  display: inline-block;
  position: absolute;
  left: -5px;
  top: 0px;
}
.chat-box .from-msg span::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #ffd8c0;
  border-left: 10px solid transparent;
  display: inline-block;
  position: absolute;
  right: -5px;
  top: 0px;
  transform: rotate(-90deg);
}
.chat-box .from-msg span::before {
  display: none;
}
