.text {
  text-align: center !important;
  font-size: xx-large !important;
  font-weight: bold !important;
}

.dashboard {
  position: absolute !important;
  display: flex !important;
  top: 10px !important;
  left: 5px !important;
}

.fillicon {
  cursor: pointer !important;
  height: 1.8rem !important;
  width: 3rem !important;
}

.createicon {
  cursor: pointer !important;
  height: 1.8rem !important;
  width: 3rem !important;
}

.login {
  display: flex !important;
  height: 100vh !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-top: 1rem !important;
}

.firsttile {
  font-size: x-large !important;
}

.firstinput {
  border-radius: 5px !important;
  border: none !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  outline: 2px !important;
  max-width: 15rem !important;
  width: 80% !important;
  height: 2rem !important;
}

.secondinput {
  margin-top: 15px !important;
  border-radius: 5px !important;
  border: none !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  outline: 2px !important;
  outline-offset: 2px !important;
  max-width: 15rem !important;
  width: 80% !important;
  height: 2rem !important;
}

.link {
  margin-left: 5px !important;
  color: red !important;
}

.signup {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  margin-top: 4px !important;
}

.accountitle {
  font-size: x-large !important;
}

.nameinput {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  max-width: 15rem !important;
  width: 80% !important;
  height: 2rem !important;
  border: none !important;
  border-radius: 5px !important;
  outline: none !important;
}

.emailinput {
  margin-top: 15px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  max-width: 15rem !important;
  width: 80% !important;
  height: 2rem !important;
  border: none !important;
  border-radius: 5px !important;
  outline: none !important;
}

/* For Chrome, Edge, Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Optional: Styling for consistency */
input[type="number"] {
  appearance: textfield;
}

.passwordinput {
  margin-top: 15px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  max-width: 15rem !important;
  width: 80% !important;
  height: 2rem !important;
  border: none !important;
  border-radius: 5px !important;
  outline: none !important;
}

.linklogin {
  margin-left: 5px !important;
  color: red !important;
}

.submitbutton {
  background-color: #1939ec !important;
  color: white !important;
  width: 5rem !important;
  height: 2rem !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer !important;
  transition: opacity 0.3s !important;
}

.searchdashboard {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  width: 100% !important;
}

.question {
  margin-bottom: 4px !important;
  cursor: default !important;
  text-align: center !important;
  font-size: x-large !important;
}

.message {
  width: 100% !important;
  max-width: 36rem !important;
  margin-top: 10px !important;
  margin-bottom: 4px !important;
  overflow-y: auto !important;
  height: 35% !important;
  background-color: white !important;
  padding: 4px !important;
  border-radius: 5px !important;
}

.typemessage {
  color: gray !important;
}

/* General styles for the message container */
.mb-2 {
  margin-top: 10px !important;
  margin-bottom: 0.5rem !important;
  /* Adjust as needed */
}

.p-2 {
  padding: 0.5rem !important;
  /* Adjust as needed */
}

.rounded {
  border-radius: 5px !important;
  /* Adjust as needed */
}

/* Styles for user messages */
.bg-blue-100 {
  background-color: #7cb9e8 !important;
  /* Light blue */
}

.text-blue-700 {
  color: white !important;
  /* Dark blue */
}

.a-bot-typing-indicator {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 20px !important;
}

.a-bot-typing-indicator span {
  width: 10px !important;
  height: 10px !important;
  background-color: #0092ca !important;
  border-radius: 50% !important;
  animation: jump 1.5s infinite ease-in-out !important;
}

.a-bot-typing-indicator span:nth-child(2) {
  animation-delay: 0.2s !important;
}

.a-bot-typing-indicator span:nth-child(3) {
  animation-delay: 0.4s !important;
}

@keyframes jump {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}

.a-bot-error {
  margin-bottom: 2px !important;
  padding: 6px !important;
  color: white !important;
  background-color: #ff9d9d !important;
  border-radius: 5px 5px 5px 0px !important;
}

.maininput {
  position: relative !important;
  width: 102% !important;
  max-width: 37.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  margin-top: 10px !important;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.sampleinput {
  border: none !important;
  outline: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  height: 40px !important;
}

.inputvalue {
  position: absolute !important;
  cursor: pointer !important;
  top: -7px !important;
  right: 13px !important;
  transform: translateY(50%) !important;
  cursor: pointer !important;
  font-size: 1.8rem !important;
}

.logout {
  background-color: #1939ec !important;
  color: white !important;
  width: 5rem !important;
  height: 2rem !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  border-radius: 5px !important;
  border: none !important;
  cursor: pointer !important;
  transition: opacity 0.3s !important;
  font-weight: bold !important;
}

.usericon {
  font-size: 2rem !important;
  cursor: pointer !important;
  color: black !important;
}

.usericon:active {
  font-size: 2rem !important;
  color: blue !important;
}

.uploadfile {
  position: absolute !important;
  cursor: pointer !important;
  top: -7px !important;
  right: 55px !important;
  transform: translateY(50%) !important;
  font-size: 1.8rem !important;
}

.a-bot-circle {
  cursor: pointer !important;
  /* background-color: rebeccapurple !important; */
  background-color: rgb(129, 15, 215) !important;
  width: 3.3rem !important;
  height: 3rem !important;
  border-radius: 34rem !important;
  position: fixed !important;
  right: 12px !important;
  /* Adjust the distance from the right edge */
  bottom: 13px !important;
  /* Adjust the distance from the bottom edge */
  font-size: 36px !important;
  /* Adjust the size of the icon */
  z-index: 1000 !important;
  /* Ensures the icon stays above other content */
  cursor: pointer !important;
}

.a-bot-chaticon {
  position: fixed !important;
  right: 20px !important;
  /* Adjust the distance from the right edge */
  bottom: 20px !important;
  /* Adjust the distance from the bottom edge */
  font-size: 36px !important;
  /* Adjust the size of the icon */
  z-index: 1000 !important;
  /* Ensures the icon stays above other content */
  cursor: pointer !important;
  /* Optional: makes the icon clickable */
  color: white !important;
}

.a-bot-popup {
  position: fixed !important;
  bottom: 50px !important;
  right: 70px !important;
  z-index: 999 !important;
  width: 350px !important;
  height: auto !important;
  transition: all 0.3s ease-in-out !important;
  padding: 10px !important;
  /* Added padding for smaller screens */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    Arial, sans-serif !important;
}

.a-bot-news-letter-popup {
  position: fixed !important;
  bottom: 50px !important;
  right: 70px !important;
  z-index: 999 !important;
  width: 350px !important;
  height: auto !important;
  transition: all 0.3s ease-in-out !important;
  padding: 10px !important;
  /* Added padding for smaller screens */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    Arial, sans-serif !important;
}

.a-bot-popup-content {
  background-color: rgb(255, 255, 255) !important;
  padding: 0px !important;
  border-radius: 16px !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  /* Ensures padding doesn't affect width/height */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.a-bot-title {
  color: white !important;
  font-size: 24px !important;
  margin: 0px !important;
}

.a-bot-description {
  color: white !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin: 0 !important;
}

.a-bot-contact-popup {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.a-bot-contact-popup-content {
  height: 100% !important;
  background-color: rgb(255, 255, 255) !important;
  padding: 0px !important;
  border-radius: 5px !important;
  border: 1px solid white !important;
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
}

.a-bot-contact-card {
  padding: 20px !important;
  border-radius: 5px 5px 0px 0px !important;
  background-color: rgb(129, 15, 215) !important;
  color: white !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .a-bot-popup {
    bottom: 30px !important;
    padding: 5px !important;
    /* Reduce padding for smaller screens */
  }
  .a-bot-news-letter-popup {
    bottom: 30px !important;
    padding: 5px !important;
    /* Reduce padding for smaller screens */
  }

  .a-bot-popup-content {
    /* padding: 15px !important; */
    bottom: 5px !important;
  }

  .a-bot-chat-window {
    margin: 5px !important;
  }
}

@media (max-width: 480px) {
  .a-bot-popup {
    width: 98% !important;
    right: 0 !important;
    bottom: 60px !important;
  }

  .a-bot-news-letter-popup {
    width: 98% !important;
    right: 0 !important;
    bottom: 60px !important;
  }

  .a-bot-popup-content {
    width: 90% !important;
    height: auto !important;
    margin: 0 5% 0 5% !important;
    /* Reduce padding for smaller screens */
  }
}

.a-bot-card {
  padding: 15px !important;
  border-radius: 5px 5px 0px 0px !important;
  background-color: rgb(129, 15, 215) !important;
  color: white !important;
}

.a-bot-card-news-letter {
  padding: 20px !important;
  background-color: #c74ed1;
  border-radius: 5px 5px 0px 0px !important;
  color: white !important;
}
.a-bot-userConnect-yes-button {
  border: none;
  background: none;
}

.a-bot-thumbs-up {
  font-size: large;
  color: dodgerblue;
  cursor: pointer;
}

.a-bot-userConnect-no-button {
  border: none;
  background: none;
}

.a-bot-thumbs-down {
  font-size: large;
  color: dodgerblue;
  cursor: pointer;
}
.a-bot-admin-title{
margin-top: -5px;
}
/* .a-bot-ip-details {
  padding-left: 5px;
  display: flex;
  gap: 15px;
  margin-top: -19px;
  justify-content: center;
  border-radius: 7px;
  font-weight: bold;
  background-color: gray;
  color: white;
} */

.a-bot-ip-details {
  padding: 10px; /* Add padding for spacing */
  display: flex;
  flex-wrap: wrap; /* Allow the items to wrap to the next line on smaller screens */
  gap: 10px; /* Adjust the gap between items */
  justify-content: center; /* Center the items */
  align-items: center; /* Align items vertically */
  border-radius: 7px;
  font-weight: bold;
  background-color: gray;
  color: white;
  text-align: center; /* Center text alignment for better readability */
}

.a-bot-ip-details p {
  margin: 0; /* Remove default paragraph margins */
  flex: 1 1 auto; /* Allow flexibility for each item */
  min-width: 150px; /* Ensure a minimum width for smaller screens */
}

@media (max-width: 768px) {
  .a-bot-ip-details {
    flex-direction: column; /* Stack items vertically for small screens */
    text-align: left; /* Adjust alignment if needed */
  }

  .a-bot-ip-details p {
    min-width: unset; /* Remove the minimum width restriction for narrow screens */
  }
}


.main-text {
  margin-bottom: 10px !important;
  text-align: left !important;
  color: white !important;
  /* font-family: sans-serif -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  font-family: monospace SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace */
  /* font-family: Arial, Helvetica, sans-serif !important; */
}

.paragraph {
  margin: 0px !important;
  padding: 0px !important;
  border: 0px !important;
  outline: 0px !important;
  font-size: 100% !important;
  word-break: break-word !important;
  color: white !important;
}

.a-bot-chat-window {
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important;
  padding: 8px !important;
}

.a-bot-chat-window-news-letter {
  flex-grow: 1 !important;
  background-color: #c74ed1;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important;
  padding: 8px !important;
}

.google-button {
  cursor: pointer !important;
  border: none !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 2.5rem !important;
  background-color: black !important;
  color: white !important;
}

.google-button:hover {
  background-color: #151516 !important;
}

.button-text {
  margin-top: 7px !important;
  margin-left: 6px !important;
  font-size: small !important;
  font-weight: bold !important;
}

.google-icon {
  font-size: 1.5rem !important;
}

.facebook-button {
  cursor: pointer !important;
  border: none !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 2.5rem !important;
  color: white !important;
  background-color: #034694 !important;
}

.facebook-button:hover {
  background-color: #0e3386 !important;
}

.facebook-icon {
  font-size: 1.5rem !important;
  margin-left: 15px !important;
}

.a-bot-chat-messages {
  overflow-y: auto !important;
  flex-grow: 1 !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
  height: 240px !important;
  border-radius: 5px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

/* Style the scrollbar itself */
.a-bot-chat-messages::-webkit-scrollbar {
  width: 6px !important;
  /* Width of the scrollbar */
  height: 6px !important;
  /* Height of the horizontal scrollbar */
}

/* Style the scrollbar track (background) */
.a-bot-chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  /* Light gray background */
  border-radius: 8px !important;
  /* Rounded corners */
}

/* Style the scrollbar thumb (the draggable part) */
.a-bot-chat-messages::-webkit-scrollbar-thumb {
  background: #cacaca !important;
  /* Dark gray thumb */
  border-radius: 8px !important;
  /* Rounded corners */
}

/* Style the scrollbar thumb on hover */
.a-bot-chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  /* Darker gray when hovered */
}

.a-bot-chat-message {
  padding: 5px 10px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  /* border-radius: 5px !important; */
}

.a-bot-chat-message.user {
  background-color: #cce5ff !important;
  align-self: flex-start !important;
  overflow-wrap: break-word !important;
}

.a-bot-chat-message.a-bot {
  background-color: #f1f1f1 !important;
  align-self: flex-end !important;
}

.a-bot-chat-input {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 10px !important;
}

.chat-input input {
  width: 80% !important;
  padding: 8px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  font-size: 14px !important;
}

.chat-input button {
  padding: 8px 16px !important;
  margin-left: 4px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  border: none !important;
}

.a-bot-chat-input input {
  width: 93% !important;
  flex: 1 !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
  font-size: 14px !important;
}

.a-bot-form-send-button {
  background-color: #151516 !important;
  border: none !important;
  border-radius: 5px !important;
  height: auto !important;
  width: auto !important;
  color: white !important;
  font-size: 12px !important;
  padding: 6px 14px !important;
}

.a-bot-controller {
  display: flex !important;
  justify-content: space-between !important;
  gap: 10px !important;
}

.a-bot-contact-us {
  color: white !important;
  margin: 0px !important;
  text-align: center !important;
}

.a-bot-form {
  background: #ffffff85 !important;
  padding: 10px !important;
  margin-top: 10px !important;
  border-radius: 5px !important;
}

.a-bot-name-label {
  display: inline-block !important;
  margin-bottom: 2px !important;
  margin-top: 5px !important;
  font-size: 12px !important;
}

.a-bot-contact-name {
  height: 1.8rem !important;
  width: 100% !important;
  font-size: 12px !important;
  padding: 0px !important;
  padding-left: 3px !important;
  margin-top: 0px !important;
  border: 1px solid #e5e2b9;
  border-radius: 6px;
}

.a-bot-email-label {
  display: inline-block !important;
  margin-bottom: 2px !important;
  font-size: 12px !important;
}

.a-bot-contact-email {
  height: 1.8rem !important;
  width: 100% !important;
  font-size: 12px !important;
  padding: 0px !important;
  padding-left: 3px !important;
  margin-top: 0px !important;
  border: 1px solid #e5e2b9;
  border-radius: 6px;
}

.a-bot-phone-label {
  display: inline-block !important;
  margin-top: 2px !important;
  font-size: 12px !important;
}

.a-bot-contact-phone {
  height: 1.8rem !important;
  width: 100% !important;
  font-size: 12px !important;
  padding: 0px !important;
  padding-left: 3px !important;
  margin-top: 0px !important;
  border: 1px solid #e5e2b9;
  border-radius: 6px;
}

.a-bot-form-send-button {
  margin-top: 7px !important;
}

.a-bot-contact-form .a-bot-form-send-button {
  cursor: pointer !important;
  float: right !important;
  transition: background-color 0.3s ease !important;
}

.a-bot-contact-form .a-bot-form-send-button[disabled] {
  cursor: not-allowed !important;
}

.form-message {
  margin-top: 10px !important;
  font-size: 14px !important;
  color: #d9534f !important; /* Red for errors */
}

.form-message.success {
  color: #5cb85c !important; /* Green for success */
}

.a-bot-form-message.a-bot-success {
  color: green !important;
  text-align: center !important;
  margin-left: -3rem !important;
  margin-top: -43px !important;
}

.a-bot-form-message.a-bot-error {
  color: red !important;
  text-align: center !important;
  margin-left: -3rem !important;
  margin-top: -43px !important;
}

.a-bot-live-session {
  padding: 0px !important;
  position: relative !important; /* Required to position the red circle relative to the button */
  background: none !important; /* Default background */
  border: none !important;
  cursor: pointer !important;
  font-size: 1.25rem !important;
  color: white !important; /* Default color */
  transition: color 0.3s ease !important; /* Smooth transition */
}

.a-bot-live-session.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.a-bot-live-session.disabled:hover::before {
  opacity: 0 !important;
  visibility: hidden !important;
}

.a-bot-live-session:hover {
  color: white !important;
}

.a-bot-live-session::after {
  content: "" !important; /* No text, just a circle */
  position: absolute !important;
  top: -2px !important; /* Adjust vertical position */
  right: 0px !important; /* Adjust horizontal position */
  width: 10px !important; /* Circle diameter */
  height: 10px !important; /* Circle diameter */
  background-color: #43cb0b !important; /* Circle color */
  border-radius: 50% !important; /* Make it a circle */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) !important; /* Optional: Add a slight shadow for effect */
}
.a-bot-live-session:hover::before {
  content: "Live Session" !important; /* Tooltip text */
  position: absolute !important;
  bottom: -25px !important; /* Position below the button */
  left: 50% !important; /* Center horizontally */
  transform: translateX(-50%) !important;
  background-color: #333 !important; /* Tooltip background */
  color: #fff !important; /* Tooltip text color */
  padding: 5px 10px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  white-space: nowrap !important; /* Prevent wrapping */
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.3s ease, visibility 0.3s ease !important;
}

.a-bot-live-session::before {
  content: "" !important; /* Default state: no tooltip text */
  opacity: 0 !important;
  visibility: hidden !important;
  transition: opacity 0.3s ease, visibility 0.3s ease !important;
}

.a-bot-closeicon {
  position: relative !important;
  font-size: 1.25rem !important;
  cursor: pointer !important;
  border: none !important;
  background-color: transparent !important;
  color: white !important;
  padding: 0px !important;
}

.a-bot-chat-head {
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline !important;
  gap: 10px !important;
  flex-wrap: wrap !important;
  margin-bottom: 6px !important;
}

.a-bot-chat-head-news-letter {
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline !important;
  gap: 10px !important;
  flex-wrap: wrap !important;
  margin-bottom: 11px !important;
}

.a-bot-next-page-link {
  background-color: transparent !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  padding: 4px 12px !important;
  gap: 5px !important;
  margin: 0px !important;
  border: none !important;
  color: white !important;
}

.a-bot-chat-message {
  max-width: 75% !important;
  padding: 10px 15px !important;
  /* border-radius: 10px 10px 10px 0px !important; */
  font-size: 14px !important;
  line-height: 1.2;
}

.a-bot-user-message {
  align-self: flex-end !important;
  /* background-color: #f1f1f1 !important; */
  /* background-color: #e0f7fa !important; Light cyan */
  /* background-color: #d0f0c0 !important; Light pastel green */
  /* background-color: #f5f5f5 !important; Soft gray */
  background-color: #e1d3eb !important;
  /* Lavender */
  color: #000 !important;
  overflow-wrap: break-word !important;
  border-radius: 10px 10px 0px 10px !important;
}

.a-bot-message {
  align-self: flex-start !important;
  background-color: #ffe5c7 !important;
  color: #000 !important;
  overflow-wrap: break-word !important;
  border-radius: 10px 10px 10px 0px !important;
}

.a-bot-system-message {
  align-self: flex-start !important;
  background-color: #ffe5c7 !important;
  color: #000 !important;
  overflow-wrap: break-word !important;
  border-radius: 10px 10px 10px 0px !important;
}

.a-bot-send-icon {
  cursor: pointer !important;
  font-size: 1.5rem !important;
  color: rgb(129, 15, 215) !important;
  height: 35px !important;
  width: 40px !important;
  border: none !important;
  background: transparent !important;
  padding: 5px 7px;
}

.typemessage {
  color: #aaa !important;
  text-align: center !important;
  font-style: italic !important;
}
